// extracted by mini-css-extract-plugin
export var root = "PlasmicPost-module--root--X-eU4";
export var box___0YaYq = "PlasmicPost-module--box___0YaYq--2-U_Y";
export var postHeader = "PlasmicPost-module--postHeader--2eTXT";
export var box___613Bd = "PlasmicPost-module--box___613Bd--1OdX2";
export var container = "PlasmicPost-module--container--1iB4B";
export var box__oTuq1 = "PlasmicPost-module--box__oTuq1--3YVP4";
export var authorBio = "PlasmicPost-module--authorBio--Av_Km";
export var svg = "PlasmicPost-module--svg--3iwUT";
export var box___3PYrZ = "PlasmicPost-module--box___3PYrZ--19JPO";
export var previousPost = "PlasmicPost-module--previousPost--jGAd2";
export var nextPost = "PlasmicPost-module--nextPost--1N1S5";
export var footer = "PlasmicPost-module--footer--2BFa1";